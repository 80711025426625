body{
    background: #f7fafc;
    font-family: Almarai, sans-serif;
}

#root{
    overflow: hidden;
}

.primary-bg-color {
    background-color: #044064 !important;
    border-color: #044064 !important;
}

.mt-content {
    margin-top: 5.5rem !important;
}

.row-content{
    padding: 0px 20px 0px 20px;
}

.input-date .react-date-picker__wrapper{
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    border-radius: 0.375rem;
    border: 1px solid #dee2e6;
}

.input-date .react-date-picker__wrapper:focus{
    color: #212529;
    background-color: #fff;
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .25);
}

.form-select, .form-control{
    padding: 0.45rem .75rem;
    font-size: 1rem;
}

.content-phone{
    display: flex;
}

.pre-phone{
    width: 16%;
    margin-right: 15px;
}

.require-input{
    color: red;
    font-size: 19px;
    position: absolute;
    right: -15px;
}

.label-input{
    position: relative;
}

.col-button{
    justify-content: space-between;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-end;
}

.border-require {
    border: 2px solid red !important;
}

.border-input-date .react-date-picker__wrapper {
    border: 2px solid red !important;
}

.dni-col-content,
.col-content {
    position: relative;
}

.p-error-profile-col{
    position: absolute;
    font-size: 12px;
    font-weight: bold;
    color: red;
}

.col-content .alert-danger{
    padding: 4px 0px 4px 15px;
}

.col-content .alert-danger p{
    margin: 0;
}

.content-birthday{
    display: flex;
}

.bd-input{
    margin-right: 3px;
}

.label-input small{
    font-size: 11px;
    font-weight: bold;
}

.content-logo {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
}

.slogan-header{
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
}

.content-terms {
    width: 50%;
    margin: 0 auto;
    text-align: center;
}


@media (max-width: 700px) {
    .dni-col-content{
        width: 60%;
    }
    .col-button{
        width: 40%;
        justify-content: center;
    }
    .row-content{
        margin: 0;
    }
    .row-content .col-content{
        margin-top: 1.5rem;
    }
    .mt-4-mobile{
        margin-top: 0 !important;
    }
    .pre-phone{
        width: 21%;
    }
    .phone{
        width: 78%;
    }
    .col-content .alert-danger{
        padding: 4px 15px 4px 15px;
    }
}
